<template>
  <div class="in-workbench">
    <div class="left">
    <el-button v-show="buttonflag" type="primary" @click="dialog" style="text-align: left">上传文件</el-button>
    </div>
    <div class="blank20"></div>
    <template>
      <el-table
        :header-cell-style="{background:'#e1e2e5',height: '90px'}"
        :row-style="{height:'70px',background:'#f8f7f7',color:'#606266'}"
        :data="tableData"
        style="width: 96%;margin-left: 0%;">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
              :border="true"
              :data="props.row.arr"
              style="width: 96%;margin-left: 2%;">
              <el-table-column
                label="上传时间"
                width="300">
                <template slot-scope="scope">
                  <i class="el-icon-time"></i>
                  <span style="margin-left: 10px">{{ scope.row.gmtCreate | dateFormater }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="文件名"
                width="400" prop="name">
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-tag type="success"><a :href="'https://' + scope.row.url" target="_blank">下载</a></el-tag>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          label="上传的用户"
          prop="name">
        </el-table-column>
      </el-table>
    </template>
    <el-dialog title="文件上传窗口" :visible.sync="UpDateUser" :append-to-body="true" width="50%" top="calc((100vh - 600px) / 2)" :center="true" :close-on-click-modal=false >
      <el-form style="text-align:center;">
        <el-row style=" display:inline-block;">
            <el-col :xs=24 :sm=12 style=" display:inline-block;">
                  <template style=" display:inline-block;">
                    <el-select v-model="Uploaddata.uploadKey" @change="changeValue" placeholder="请选择上传的文件类型">
                      <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.name"
                        :value="item.name">
                      </el-option>
                    </el-select>
                  </template>
                  <div class="blank20"></div>
                  <el-upload
                    style=" display:inline-block;"
                    class="upload-demo"
                    drag
                    data:Uploaddata
                    :action=UploadUrl
                    :file-list="fileList"
                    :auto-upload="true"
                    :disabled="uploadflag"
                    multiple>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
<!--                    <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
                  </el-upload>
                </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">

        <el-button type="danger" @click="back()">上传完成</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import clientUtils from "../../js/clientUtils";
import enums from "../../js/enums";

export default {
  name: "otherFile",
  data: function () {
    return {
      Uploaddata:{},
      tableData: [],
      fileList:[],
      buttonflag:true,
      options:[{id:1,name:"集团模板"},{id:2,name:"公司模板"},{id:3,name:"应急预案"},{id:4,name:"知识培训"},{id:5,name:'签字审批付款类'},{id:6,name:'签字审批工程类'},{id:7,name:'签字审批新增类'},{id:8,name:'签字审批资产类'}],
      UploadUrl:"http://39.103.237.127:8160/system/file/upload/oss/"+clientUtils.getLocalUserInfo().id,
      UpDateUser: false,
      categoryName :'',
      uploadflag:false
    };
  },
  filters: {
    dateFormater: function (datetime) {
      return clientUtils.formatDatetime(datetime, enums.datetimeFormat.FULL_CHS)
    }
  },

  methods: {
    back() {
      this.UpDateUser = false
      this.uploadflag=false
      console.log(JSON.stringify(this.Uploaddata))
    },
    dialog() {
      this.fileList =[]
      this.UpDateUser = true
      this.uploadflag=true
      this.Uploaddata={}
      clientUtils.popInfoMessage("请先选择上传文件的类型")
    },
    changeValue:function () {
      this.uploadflag=false
    }
  },

  mounted() {
    const category = this.$route.params.category;
    console.log('用户名' + clientUtils.getLocalUserInfo().username);
    console.log('这是' + category);
    // let categoryName = '';
    switch (category) {
      case 'othermouthPlan':
        this.categoryName = '其他人的月计划';
        this.$emit('selectMenu', this.$route.path, '其他人月计划');
        this.fileUrl = '/system/ihsFile/selectPage/otherMonthPlan?current=' + this.fillpage + '&size=10';
        console.log('路径' + this.$route.path);
        break;
      case 'otherweekPlan':
        this.categoryName = '其他人周计划';
        this.$emit('selectMenu', this.$route.path, '其他人周计划');
        this.fileUrl = '/system/ihsFile/selectPage/otherWeekPlan?current=' + this.fillpage + '&size=10&id=' + clientUtils.getLocalUserInfo().id;
        console.log('路径' + this.$route.path);
        break;
      case 'otherFiles':
        this.categoryName = '其他人的文件';
        this.$emit('selectMenu', this.$route.path, '其他文件');
        this.fileUrl ='/system/ihsFile/selectPage/otherFiles?current=1&size=10&id=1';
        console.log('路径' + this.$route.path);
        break;
      default:
        this.categoryName = ''
    }
    if (this.categoryName === '') {
      this.$router.push('/default')
    } else {
      // 参数正确
      this.category = category
    }
    if (category == 'othermouthPlan' || category == 'otherweekPlan' || category == 'otherFiles') {
      const that = this;
      clientUtils.get(this.fileUrl, function (res) {
        const there = that;
        if (res.data != null&&res.data.code == "200") {
          console.log(res.data)
          let arr = [];
         const here = there;
          for (const resKey in res.data.data) {
            let obj = {};
            obj.name = resKey;
            obj.arr=res.data.data[resKey];
            console.log(obj.name);
            console.log(obj.arr);
            arr.push(obj);
          here.tableData =arr
          }
        }
        else {
          that.buttonflag = false
        }
      })
    }
  }

}
</script>

<style scoped>
.left{
  text-align: left;
}
</style>
