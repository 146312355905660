import { render, staticRenderFns } from "./otherFile.vue?vue&type=template&id=f2434024&scoped=true&"
import script from "./otherFile.vue?vue&type=script&lang=js&"
export * from "./otherFile.vue?vue&type=script&lang=js&"
import style0 from "./otherFile.vue?vue&type=style&index=0&id=f2434024&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2434024",
  null
  
)

export default component.exports